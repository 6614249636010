<template>
  <div class="home">
    <div class="filter-wrapper">
      <div>
        <v-select
          v-model="dataSelectLastDayValue"
          :options="dataSelectOptionsLastDay"
          :filterable="false"
          :searchable="false"
          :components="{Deselect: dataComponentDeselect}"
          placeholder="Last Days"
          :class="['call-select', dataSelectLastDayValue ? 'call-select--value' : null]"
          @input="$_ajax">
          <template #open-indicator="{ attributes }">
            <span v-bind="attributes">
              <font-awesome-icon :icon="dataIconDown" />
            </span>
          </template>
        </v-select>
      </div>
    </div>
    <div class="table-wrapper">
      <b-table
        striped
        hover
        no-local-sorting
        :fields="dataMixinReportFields"
        :items="dataReportList"
        @row-hovered="onHover"
        @row-clicked="(value) => onActions(value, 'view')"
        @row-unhovered="onUnHover">
        <template #head(outcoming_finished_calls_count)>
          <span class="success">
            Calls
          </span>
          Finished
        </template>

        <template #head(outcoming_cancelled_calls_count)>
          <span class="danger">
            Calls
          </span>
          Cancelled
        </template>

        <template #head(auto_closed_free_tasks_count)>
          <span>
            Closed
          </span>
          Free
        </template>

        <template #head(auto_closed_assigned_tasks_count)>
          <span class="success">
            Closed
          </span>
          Assigned
        </template>
        <template #head(auto_closed_assigned_vip_tasks_count)>
          <span class="success">
            Closed
          </span>
          VIP
        </template>

        <template #head(manually_closed_tasks_count)>
          <span class="danger">
            Closed
          </span>
          Manually
        </template>

        <template #cell(outcoming_finished_calls_count)="data">
          <span class="success">{{ data.item.outcoming_finished_calls_count }}</span>
        </template>
        <template #cell(outcoming_cancelled_calls_count)="data">
          <span class="danger">{{ data.item.outcoming_cancelled_calls_count }}</span>
        </template>
        <template #cell(auto_closed_assigned_tasks_count)="data">
          <span class="success">{{ data.item.auto_closed_assigned_tasks_count }}</span>
        </template>
        <template #cell(auto_closed_assigned_vip_tasks_count)="data">
          <span class="success">{{ data.item.auto_closed_assigned_vip_tasks_count }}</span>
        </template>
        <template #cell(manually_closed_tasks_count)="data">
          <span class="danger">{{ data.item.manually_closed_tasks_count }}</span>
        </template>
        <template #cell(operators_count)="data">
          {{ data.item.operators_count || 0 }}
        </template>

        <template #cell(actions)="data">
          <call-table-report-actions
            v-show="data.item.actions"
            @on-action="(value) => onActions(data.item, value)" />
        </template>
        <template #cell(outcoming_cancelled_calls_count)="data">
          <span class="danger"> {{ data.item.outcoming_cancelled_calls_count || 0 }} </span>
        </template>
      </b-table>
    </div>
  </div>
</template>

<script>
import { BTable } from 'bootstrap-vue';
import { REPORTS_GET_ALL } from '../../../store/types/actions.types';
import reportsTable from '../../../mixins/table/reportsTable';
import CallTableReportActions from '../../../components/desktop/CallTableReportActions';
import helper from '../../../service/helper';
import selectReportPeriod from '../../../mixins/select/selectReportPeriod';

export default {
  name: 'PageReports',
  components: {
    CallTableReportActions,
    BTable
  },
  mixins: [reportsTable, selectReportPeriod],
  data () {
    return {
      dataReportList: []
    };
  },
  async created () {
    this.$_ajax = ((options = {}) => {
      const { currentRoute: { name: pageName } = {} } = this.$router;

      const _filterKey = `_filter${pageName}`;

      if (!helper.isEmpty(this.$route.query, _filterKey)) {
        try {
          options = helper.clearObject(JSON.parse(this.$route.query[_filterKey]));

          const _arrFilter = [
            'period'
          ];

          for (const _key of _arrFilter) {
            if (!helper.isEmpty(options, _key)) {
              switch (_key) {
                case 'period':
                  this.dataSelectLastDayValue = options[_key];
                  break;
              }
            }
          }
        } catch (e) {
          console.log('Error query params');
        }
      }

      return async () => {
        if (!this.dataSelectLastDayValue) {
          this.dataSelectLastDayValue = { ...this.dataSelectLastDayValueDefault };
        }
        const { payload: { teams = [] } } = await this.$store.dispatch(`report/${REPORTS_GET_ALL}`, {
          params: {
            period: this.dataSelectLastDayValue?.key
          }
        });

        this.dataReportList = teams.map((item) => {
          item.actions = false;
          return item;
        });

        this.$router.replace({
          ...this.$router.currentRoute,
          query: {
            [_filterKey]: JSON.stringify(helper.clearObject({
              period: this.dataSelectLastDayValue
            }))
          }
        }).catch(() => {});

        options = {};
      };
    })();

    await this.$_ajax();
  },
  methods: {
    async  onActions ({ id: reportId, is_blocked: isBlocked, name = '' }, action) {
      switch (action) {
        case 'view':
          this.$router.push({
            name: 'ReportDetail',
            params: {
              reportId
            },
            query: {
              reportName: name
            }
          });
          break;
      }
    }
  }
};
</script>
