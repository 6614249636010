export default {
  data () {
    return {
      dataMixinReportFields: [
        {
          label: 'ID',
          key: 'id',
          sortable: false,
          tdClass: 'call-td call-td--grey',
          thClass: 'call-th call-tabel--span'
        },
        {
          label: 'Team',
          key: 'name',
          sortable: false,
          tdClass: 'call-td call-td--bold',
          thClass: 'call-th call-tabel--span'
        },
        {
          label: 'Leader',
          key: 'lead_name',
          sortable: false,
          tdClass: 'call-td',
          thClass: 'call-th call-tabel--span'
        },
        {
          label: 'Operators',
          key: 'operators_count',
          sortable: false,
          tdClass: 'call-td call-tabel--center',
          thClass: 'call-th call-tabel--center call-tabel--span'
        },
        {
          label: 'Calls Finished',
          key: 'outcoming_finished_calls_count',
          sortable: false,
          tdClass: 'call-td call-tabel--center',
          thClass: 'call-th call-tabel--center call-tabel--span'
        },

        {
          label: 'Calls Cancelled',
          key: 'outcoming_cancelled_calls_count',
          sortable: false,
          tdClass: 'call-td call-tabel--center',
          thClass: 'call-th call-tabel--center call-tabel--span'
        },

        {
          label: 'Closed Auto Free',
          key: 'auto_closed_free_tasks_count',
          sortable: false,
          tdClass: 'call-td call-tabel--center',
          thClass: 'call-th call-tabel--center call-tabel--span'
        },

        {
          label: 'Closed Auto Assigned',
          key: 'auto_closed_assigned_tasks_count',
          sortable: false,
          tdClass: 'call-td call-tabel--center',
          thClass: 'call-th call-tabel--center call-tabel--span'
        },
        {
          label: 'Closed Auto VIP',
          key: 'auto_closed_assigned_vip_tasks_count',
          sortable: false,
          tdClass: 'call-td call-tabel--center',
          thClass: 'call-th call-tabel--center call-tabel--span'
        },

        {
          label: 'Closed Manually',
          key: 'manually_closed_tasks_count',
          sortable: false,
          tdClass: 'call-td call-tabel--center',
          thClass: 'call-th call-tabel--center call-tabel--span'
        },

        {
          label: '',
          key: 'actions',
          tdClass: 'call-tabel-action',
          thClass: 'call-tabel-action call-tabel--span'
        }
      ]
    };
  },
  methods: {
    onHover (item) {
      item.actions = true;
    },
    onUnHover (item) {
      item.actions = false;
    }
  }
};
