<template>
  <div class="table_actions">
    <call-tooltip
      placement="top"
      content="Info">
      <call-button
        outlet
        type="success"
        round
        not-border
        :icon="dataViewIcon"
        @click="clickAction('view')" />
    </call-tooltip>
  </div>
</template>

<script>
import CallButton from '../common/CallButton';
import { faEye } from '@fortawesome/free-solid-svg-icons';

export default {
  name: 'CallTableReportActions',
  components: { CallButton },
  data () {
    return {
      dataViewIcon: faEye
    };
  },
  methods: {
    clickAction (action) {
      this.$emit('on-action', action);
    }
  }
};
</script>
